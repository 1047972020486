import React, { useState, useEffect } from 'react';
import './price.scss'
import { Helmet } from 'react-helmet';
import Price1 from './Price1';
import Price3 from './Price3';
import Price2 from './Price2';
import Price4 from './Price4';
import Price5 from './Price5';
import Price from '../Price/Price';
import Price6 from './Price6';

const PriceRoute = ({ setShowTermBundle, setButtonShow, setPriceBottomShow, setWhiteWrapper, sessionId, setButtonText, setEventName, setEventProperties }) => {

    useEffect(() => {
        setButtonShow(true);
        // if (setWhiteWrapper) {
        //     setWhiteWrapper(true)
        // }
        setPriceBottomShow(false);
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const [PriceElement, setPriceElement] = useState()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const versionParam = queryParams.get('version');

        switch (versionParam) {
            case "1":
                setPriceElement(
                    <Price1 
                        sessionId={sessionId} 
                        setShowTermBundle={setShowTermBundle} 
                        setPriceBottomShow={setPriceBottomShow} 
                        setButtonShow={setButtonShow} 
                        setWhiteWrapper={setWhiteWrapper} 
                    />
                );
                setButtonShow(false)
                break;
            case "2":
                setButtonShow(false)
                setPriceElement(
                    <Price2 
                        sessionId={sessionId} 
                        setShowTermBundle={setShowTermBundle} 
                        setPriceBottomShow={setPriceBottomShow} 
                        setButtonShow={setButtonShow} 
                        setWhiteWrapper={setWhiteWrapper} 
                    />
                );
                setButtonShow(false)
                break;
            case "3":
                setPriceElement(
                    <Price3 
                        sessionId={sessionId} 
                        setShowTermBundle={setShowTermBundle} 
                        setPriceBottomShow={setPriceBottomShow} 
                        setButtonShow={setButtonShow} 
                        setWhiteWrapper={setWhiteWrapper} 
                    />
                );
                setButtonShow(false)
                break;
            case "4":
                setPriceElement(
                    <Price4 
                        sessionId={sessionId} 
                        setShowTermBundle={setShowTermBundle} 
                        setPriceBottomShow={setPriceBottomShow} 
                        setButtonShow={setButtonShow} 
                        setWhiteWrapper={setWhiteWrapper} 
                    />
                );
                setButtonShow(false)
                break;
            case "5":
                setPriceElement(
                    <Price5 
                        sessionId={sessionId} 
                        setShowTermBundle={setShowTermBundle} 
                        setPriceBottomShow={setPriceBottomShow} 
                        setButtonShow={setButtonShow} 
                        setWhiteWrapper={setWhiteWrapper} 
                    />
                );
                setButtonShow(false)
                break;
            default:
                setPriceElement(
                    <Price setEventProperties={setEventProperties} setEventName={setEventName} setButtonText={setButtonText} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setPriceBottomShow={setPriceBottomShow} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} />
                );
                setButtonShow(true)
                break;
        }
        
        // Скрываем кнопку для всех версий
        setButtonShow(false);


        // if (versionParam == 6) {
        //     setPriceElement(<Price6 sessionId={sessionId} setShowTermBundle={setShowTermBundle} setPriceBottomShow={setPriceBottomShow} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} />)
        //     setButtonShow(false)
        // }

        
 
    }, []);

    return (
        <div>
            <Helmet>
                <meta name="theme-color" content="#fff"></meta>
            </Helmet>
            {PriceElement} 
        </div>
    );
}

export default PriceRoute;
